import React from 'react';
// @ts-ignore
import Toolbar from '../../components/Toolbar/Toolbar';
import AlemanaStatus from '../../components/AlemanaStatus/AlemanaStatus';
import CardIndicadores from '../../components/CardIndicardores/CardIndicadores';
import CardWrapper from '../../components/CardWrapper/CardWrapper';
import InfoHost from '../../components/InfoHost/InfoHost';
import InfoItemIndicadores from '../../components/InfoItemIndicadores/InfoItemIndicadores';
import { icons } from '../../utils/dictionaryIcons';
import { zabbix_states } from '../../utils/dictionaryStates';

import './ClientesIndicadores.css';

const alemanStatusArray = [
  {
    status: 'NOTDEFINED',
    icon: 'alemana_go',
  },
  {
    status: 'NOTDEFINED',
    title: 'Nuevas Agendas',
    icon: 'alemana_logo',
  },
  {
    status: 'NOTDEFINED',
    title: 'Movimiento Pacientes',
    icon: 'alemana_logo',
  },
];

const webLogicItems = {
  SG_Parametros: {
    label: 'Sistema',
    status: 'NOTDEFINED',
    width: '198',
  },
  Indicadores: {
    // label: 'Sistema',
    status: 'NOTDEFINED',
    width: '198',
  },
  V3: {
    label: 'Mantenedor de clientes',
    status: 'OK',
    width: '198',
    items: [
      {
        title: 'Convenios',
        status: 'NOTDEFINED',
      },
      {
        title: 'Agenda',
        status: 'NOTDEFINED',
      },
      {
        title: 'Fase 1',
        status: 'NOTDEFINED',
      },
      {
        title: 'Fase 2',
        status: 'NOTDEFINED',
      },
    ],
  },
};

const oracle = {
  status: 0,
  items: {
    Clientes_V2: {
      label: 'Sistema',
      status: 4,
      width: '198',
    },
    SINDICADORES: {
      label: 'Sistema',
      status: 4,
      width: '198',
    },
    SGPARAMETROS: {
      label: 'Sistema',
      status: 4,
      width: '198',
    },
  },
};

const tanzuItems = {
  TYK: {
    label: 'Validacion de seguridad',
    status: 'NOTDEFINED',
    width: '198',
  },
  APIOR: {
    label: 'API',
    status: 'NOTDEFINED',
    width: '198',
  },
  APIJUR: {
    label: 'API',
    status: 'NOTDEFINED',
    width: '198',
  },
  APIPARAM: {
    label: 'API',
    status: 'NOTDEFINED',
    width: '198',
  },
  APICLI: {
    label: 'API principal',
    status: 'NOTDEFINED',
    width: '198',
    items: [
      {
        title: 'Operaciones',
        status: 'NOTDEFINED',
      },
      {
        title: 'Consultas',
        status: 'NOTDEFINED',
      },
    ],
  },
  V4: {
    label: 'Sistema',
    status: 'NOTDEFINED',
    width: '198',
    items: [
      {
        title: 'Sistema interno',
        status: 'NOTDEFINED',
      },
    ],
  },
  Replica_cliente: {
    label: 'Informacion del cliente',
    status: 'NOTDEFINED',
    items: [
      {
        title: 'Creacion y Modificacion de Clientes',
        status: 'NOTDEFINED',
      },
      {
        title: 'Instancias Colas Nats Stream',
        status: 'NOTDEFINED',
        items: {
          SAP: {
            status: 'NOTDEFINED',
          },
          Roble: {
            status: 'NOTDEFINED',
          },
          Nucleo: {
            status: 'NOTDEFINED',
          },
          Ago: {
            status: 'NOTDEFINED',
          },
          Quimioprocess: {
            status: 'NOTDEFINED',
          },
          Fase_1: {
            status: 'NOTDEFINED',
          },
          Tuo_Tempo_Temuco: {
            status: 'NOTDEFINED',
          },
          Tuo_Tempo_Valdivia: {
            status: 'NOTDEFINED',
          },
          Salesforce: {
            status: 'NOTDEFINED',
          },
          Modulab: {
            status: 'NOTDEFINED',
          },
        },
      },
      {
        title: 'Desencoladores',
        status: 'NOTDEFINED',
      },
    ],
  },
  Red_Hat: {
    label: 'RHSSO',
    status: 'OK',
    icon: 'redhat',
    width: '237',
  },
  Azure: {
    label: 'Autentificacion',
    status: 'NOTDEFINED',
    icon: 'azure',
    width: '237',
  },
};

function ClientesIndicadores({
  selectMainDashboard,
  ldapStatus,
  infoIndicatorClients,
  tanzu,
  subscriptoresClientesIndicadores,
}) {
  console.log(subscriptoresClientesIndicadores);
  let oracleInfo = infoIndicatorClients['oracleInfo'] || oracle;
  function getAlemanaStatus() {
    return alemanStatusArray.map((eachState, index) => {
      if (eachState.title && eachState.icon) {
        const bodyAlemana = (
          <div className="alemana-status-body-title-icon">
            <div className="icon">
              <img src={icons[eachState.icon]} />
            </div>
            <div className="title">{eachState.title}</div>
          </div>
        );
        return (
          <AlemanaStatus status={eachState.status} key={`${index}-each-icon-alemana-status`}>
            {bodyAlemana}
          </AlemanaStatus>
        );
      } else if (eachState.icon) {
        const bodyAlemana = (
          <div className="alemana-status-body-icon">
            <img src={icons[eachState.icon]} />
          </div>
        );
        return (
          <AlemanaStatus status={eachState.status} key={`${index}-each-icon-alemana-status`}>
            {bodyAlemana}
          </AlemanaStatus>
        );
      }
    });
  }

  function getSecondColumnContent() {
    const items = [];
    const itemsIcons = [];
    for (const device in tanzuItems) {
      const itemsDevicesCmpt = [];
      const deviceInfo = tanzuItems[device];
      const itemsDevice = deviceInfo.items && deviceInfo.items.length ? deviceInfo.items : [];
      let icon = null;
      if (deviceInfo.icon) {
        icon = <img src={icons[deviceInfo.icon]} style={{ width: '100%', height: 'auto' }} />;
        // console.log('if', deviceInfo);
        let cardIndStatus = deviceInfo.status;
        if (device === 'APIPARAM') {
          cardIndStatus = subscriptoresClientesIndicadores['paramStatus'] || 'UNK'
        } else if (device === 'APICLI') {
          cardIndStatus = subscriptoresClientesIndicadores['cliStatus'] || 'UNK'
        } else if (device === 'APIJUR') {
          cardIndStatus = subscriptoresClientesIndicadores['jurStatus'] || 'UNK'
        } else if (device === 'V4') {
          cardIndStatus = subscriptoresClientesIndicadores['v4Status'] || 'UNK'
        }
        itemsIcons.push(
          <div className={`${device}-class`}>
            <CardIndicadores
              icon={icon}
              title={device}
              label={deviceInfo.label}
              status={cardIndStatus}
              style={{
                width: `${deviceInfo.width}px`,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                backgroundColor: '#ffffff',
                border: '2px solid #76cce7',
              }}
            >
              {itemsDevicesCmpt}
            </CardIndicadores>
          </div>
        );
      } else {
        // console.log('else', deviceInfo);
        itemsDevice.forEach((itDev) => {
          if (itDev.title === 'Instancias Colas Nats Stream') {
            console.log(subscriptoresClientesIndicadores);

            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                // iconName={'query'}
                status={subscriptoresClientesIndicadores['natsStream'] || 'UNK'}
                itemName={itDev.title}
                // itemLabel={'Registro de pagos'}
                key={`item-query1`}
              />
            );
          } else if (itDev.title === 'Operaciones') {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                status={subscriptoresClientesIndicadores['cliOperaciones'] || 'UNK'}
                itemName={itDev.title}
                key={`item-query1`}
              />
            );
          } else if (itDev.title === 'Consultas') {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                status={subscriptoresClientesIndicadores['cliConsultas'] || 'UNK'}
                itemName={itDev.title}
                key={`item-query1`}
              />
            );
          } else if (itDev.title === 'Sistema interno') {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                status={subscriptoresClientesIndicadores['v4Status'] || 'UNK'}
                itemName={itDev.title}
                key={`item-query1`}
              />
            );
          } else {
            itemsDevicesCmpt.push(
              <InfoItemIndicadores
                status={itDev.status}
                itemName={itDev.title}
                key={`item-query1`}
              />
            );
          }
        });
        let cardIndStatus = deviceInfo.status;
        if (device === 'APIPARAM') {
          cardIndStatus = subscriptoresClientesIndicadores['paramStatus'] || 'UNK'
        } else if (device === 'APICLI') {
          cardIndStatus = subscriptoresClientesIndicadores['cliStatus'] || 'UNK'
        } else if (device === 'APIJUR') {
          cardIndStatus = subscriptoresClientesIndicadores['jurStatus'] || 'UNK'
        } else if (device === 'V4') {
          cardIndStatus = subscriptoresClientesIndicadores['v4Status'] || 'UNK'
        }
        items.push(
          <div className={`${device}-class`}>
            <CardIndicadores
              icon={icon}
              title={device}
              label={deviceInfo.label}
              status={cardIndStatus}
              style={{ width: `${deviceInfo.width}px` }}
            >
              {itemsDevicesCmpt}
            </CardIndicadores>
          </div>
        );
      }
    }
    // console.log(tanzu);
    let tanzuStatus = 'OK';
    tanzu.forEach((tanzuElement) => {
      if (tanzuElement.status !== 'OK') {
        tanzuStatus = 'CRITICAL';
      }
    });

    return (
      <div
        className="card-wrap-second"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '140px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {itemsIcons}
        </div>
        <CardWrapper
          status={tanzuStatus}
          mainContent={<InfoHost iconName={'server'} type={'Servidor'} hostname={'Tanzu'} ip={`IP 172.12.1.11`} />}
          items={items}
          style={{ width: '741px', height: '659px' }}
        />
      </div>
    );
  }

  function getThirdColumnContent() {
    const items = [];
    const itemsOracle = [];
    // const itemsIcons = [];
    for (const device in webLogicItems) {
      const itemsDevicesCmpt = [];
      const deviceInfo = webLogicItems[device];
      const itemsDevice = deviceInfo.items && deviceInfo.items.length ? deviceInfo.items : [];
      let icon = null;
      itemsDevice.forEach((itDev) => {
        itemsDevicesCmpt.push(
          <InfoItemIndicadores
            // iconName={'query'}
            status={itDev.status}
            itemName={itDev.title}
            // itemLabel={'Registro de pagos'}
            key={`item-query1`}
          />
        );
      });
      items.push(
        <div className={`${device}-class`}>
          <CardIndicadores
            // icon={icon}
            title={device}
            label={deviceInfo.label}
            status={deviceInfo.status}
            style={{ width: `${deviceInfo.width}px` }}
          >
            {itemsDevicesCmpt}
          </CardIndicadores>
        </div>
      );
    }

    for (const device in oracleInfo['items']) {
      // const itemsDevicesCmpt = [];
      const deviceInfo = oracleInfo['items'][device];
      // const itemsDevice = deviceInfo.items && deviceInfo.items.length ? deviceInfo.items : [];
      // let icon = null;
      // itemsDevice.forEach((itDev) => {
      //   itemsDevicesCmpt.push(
      //     <InfoItemIndicadores
      //       // iconName={'query'}
      //       status={itDev.status}
      //       itemName={itDev.title}
      //       // itemLabel={'Registro de pagos'}
      //       key={`item-query1`}
      //     />
      //   );
      // });
      itemsOracle.push(
        <div className={`${device}-class`}>
          <CardIndicadores
            // icon={icon}
            title={device}
            label={deviceInfo.label}
            status={zabbix_states[deviceInfo.status]}
            style={{ width: `${deviceInfo.width}px` }}
          >
            {/* {itemsDevicesCmpt} */}
          </CardIndicadores>
        </div>
      );
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CardWrapper
          status={'NOTDEFINED'}
          mainContent={<InfoHost iconName={'server'} type={'Servidor'} hostname={'Weblogic11'} ip={`IP 11.11.11.11`} />}
          items={items}
          style={{ width: '226px' }}
        />
        <CardWrapper
          status={'NOTDEFINED'}
          mainContent={<InfoHost iconName={'computer2'} type={'Servidor'} hostname={'Tuxedo'} ip={`Middleware`} />}
          // items={items}
          style={{ width: '226px' }}
        />
        <CardWrapper
          status={zabbix_states[oracleInfo['status']]}
          mainContent={<InfoHost iconName={'database'} type={'Servidor'} hostname={'ORACLE'} ip={`BD Roble`} />}
          items={itemsOracle}
          style={{ width: '226px' }}
        />
      </div>
    );
  }

  // function getWebLogic() {
  //   return (
  //     <CardWrapper
  //       status={'NOTDEFINED'}
  //       mainContent={<InfoHost iconName={'server'} type={'Servidor'} hostname={'Weblogic11'} ip={`IP 11.11.11.11`} />}
  //       items={items}
  //       style={{ width: '235px', height: '509px' }}
  //     />
  //   );
  // }

  return (
    <section className="clientes-indicadores-view">
      <Toolbar title={`Indicadores`} />
      <div className="content">
        <div className="first-column">
          <div className="first-column-content">
            <div className="cc-item"></div>
            <div className="cc-item items">{getAlemanaStatus()}</div>
            <div className="cc-item"></div>
          </div>
        </div>
        <div className="second-column">
          {getSecondColumnContent()}
          <div className="subscriptors-fase1" style={{ top: '-130px', left: '350px' }}>
            <InfoItemIndicadores status={subscriptoresClientesIndicadores['fase1'] || 'UNK'} itemName={'Fase 1'} />
          </div>
          <div className="subscriptors-salesforce" style={{ top: '-130px', left: '350px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['salesforce'] || 'UNK'}
              itemName={'Salesforce'}
            />
          </div>
          <div className="subscriptors-temuco" style={{ top: '-205px', left: '620px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['temuco'] || 'UNK'}
              itemName={'Tuo Tempo Temuco'}
            />
          </div>
          <div className="subscriptors-valdivia" style={{ top: '-205px', left: '620px' }}>
            <InfoItemIndicadores
              status={subscriptoresClientesIndicadores['valdivia'] || 'UNK'}
              itemName={'Tuo Tempo Valdivia'}
            />
          </div>
        </div>
        <div className="third-column">
          <div className={`Ldap-class`}>
            <CardIndicadores
              icon={<img src={icons['ldap']} style={{ width: '100%', height: 'auto' }} />}
              title={'L-Dap'}
              label={'Autenticacion'}
              status={ldapStatus}
              style={{
                width: `237px`,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                backgroundColor: '#ffffff',
                border: '2px solid #76cce7',
              }}
            ></CardIndicadores>
          </div>
        </div>
        <div className="fourth-column">{getThirdColumnContent()}</div>
        {/* FLECHAS DE LA IZQUIERDA HACIA ALEMANA GO */}
        <div className="line" style={{ width: '3px', height: '205px', top: '335px', left: '300px' }}></div>
        <div className="line" style={{ width: '74px', top: '335px', left: '226px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '330px', left: '224px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '339px', left: '224px' }}></div>

        <div className="line" style={{ width: '124px', top: '437px', left: '226px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '432px', left: '224px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '441px', left: '224px' }}></div>

        <div className="line" style={{ width: '74px', top: '537px', left: '226px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '532px', left: '224px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '541px', left: '224px' }}></div>

        {/* ORACLE - WEBLOGIC */}
        <div className="line" style={{ width: '3px', height: '470px', top: '171px', left: '1730px' }}></div>
        <div className="line" style={{ width: '31px', top: '171px', left: '1700px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '167px', left: '1698px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '175px', left: '1698px' }}></div>

        <div className="line" style={{ width: '31px', top: '638px', left: '1700px' }}></div>

        {/* V3 - TUXEDO */}
        <div className="line" style={{ width: '3px', height: '54px', top: '434px', left: '1590px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '482px', left: '1589px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '482px', left: '1581px' }}></div>

        {/* TUXEDO - ORACLE */}
        <div className="line" style={{ width: '3px', height: '41px', top: '558px', left: '1590px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '592px', left: '1589px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '592px', left: '1581px' }}></div>

        <div className="bottom-arrow" style={{ width: '14px', top: '562px', left: '1580px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '562px', left: '1589px' }}></div>

        {/* LDAP - Agenda */}
        <div className="line" style={{ width: '189px', top: '335px', left: '1299px' }}></div>
        <div className="line" style={{ width: '3px', height: '72px', top: '338px', left: '1299px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '402px', left: '1299px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '402px', left: '1288px' }}></div>

        {/* INDICADORES - TYK */}
        <div className="line" style={{ width: '189px', top: '163px', left: '1299px' }}></div>
        <div className="line" style={{ width: '3px', height: '158px', top: '5px', left: '1299px' }}></div>
        <div className="line" style={{ width: '794px', top: '5px', left: '505px' }}></div>
        <div className="line" style={{ width: '3px', height: '417px', top: '5px', left: '505px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '416px', left: '504px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '416px', left: '495px' }}></div>

        {/* V3 - TYK */}
        <div className="line" style={{ width: '205px', top: '267px', left: '1283px' }}></div>
        <div className="line" style={{ width: '3px', height: '244px', top: '23px', left: '1283px' }}></div>
        <div className="line" style={{ width: '746px', top: '20px', left: '540px' }}></div>
        <div className="line" style={{ width: '3px', height: '399px', top: '23px', left: '540px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '416px', left: '539px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '416px', left: '530px' }}></div>

        {/* CLIENTES_V2 - TANZU */}
        <div className="line" style={{ width: '333px', top: '690px', left: '1155px' }}></div>
        <div className="line" style={{ width: '3px', height: '380px', top: '313px', left: '1152px' }}></div>
        <div className="line" style={{ width: '76px', top: '313px', left: '1076px' }}></div>
        <div className="line" style={{ width: '76px', top: '470px', left: '1076px' }}></div>
        <div className="line" style={{ width: '76px', top: '606px', left: '1076px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '309px', left: '1075px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '317px', left: '1075px' }}></div>

        <div className="bottom-arrow" style={{ width: '14px', top: '464px', left: '1075px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '474px', left: '1075px' }}></div>

        <div className="bottom-arrow" style={{ width: '14px', top: '602px', left: '1075px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '610px', left: '1075px' }}></div>

        {/* CLIENTES_V2 - APIOR */}
        <div className="line" style={{ width: '26px', top: '313px', left: '834px' }}></div>
        <div className="line" style={{ width: '3px', height: '230px', top: '313px', left: '860px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '309px', left: '833px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '317px', left: '833px' }}></div>
        <div className="line" style={{ width: '289px', top: '540px', left: '863px' }}></div>

        {/* CLIENTES_V2 - APIJUR */}
        <div className="line" style={{ width: '26px', top: '423px', left: '834px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '419px', left: '833px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '427px', left: '833px' }}></div>

        {/* V4 - TYK */}
        <div className="line" style={{ width: '3px', height: '31px', top: '482px', left: '465px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '485px', left: '464px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '485px', left: '455px' }}></div>

        {/* V4 - AZURE */}

        {/* TYK - REDHAT */}
      </div>
      <div className="back-icon" onClick={selectMainDashboard}>
        <div>
          <img src={icons['back']} />
        </div>
      </div>
    </section>
  );
}

export default ClientesIndicadores;
