import React from 'react';
import Logo from '../../img/logo_cla.png';
import Toolbar from '../../components/Toolbar/Toolbar';
import LogoTransback from '../../img/transback_logo.svg';
import ApiIcon from '../../img/api_icon.svg';
import CajaIcon from '../../img/caja_icon.svg';
import CardInfo from '../../components/CardInfo/CardInfo';
import InfoItem from '../../components/InfoItem/InfoItem';
import InfoHost from '../../components/InfoHost/InfoHost';
import { icons } from '../../utils/dictionaryIcons';
import './Transbank.css';

function Transbank({ infoDashboard, orderColumns, selectMainDashboard }) {
  console.log(infoDashboard);
  // console.log(orderColumns);
  const headerCardDemoVitacura = (
    <div style={{ width: '100%', position: 'relative', marginBottom: '15px' }}>
      <img src={Logo} style={{ width: '200px' }} />
      <div
        style={{
          fontSize: '24px',
          lineHeight: '38.54px',
          fontWeight: '100',
          color: '#596C88',
          position: 'absolute',
          left: '50px',
          top: '35px',
        }}
      >
        {'Vitacura'}
      </div>
    </div>
  );
  const headerCardDemoLaDehesa = (
    <div style={{ width: '100%', position: 'relative', marginBottom: '15px' }}>
      <img src={Logo} style={{ width: '200px' }} />
      <div
        style={{
          fontSize: '24px',
          lineHeight: '38.54px',
          fontWeight: '100',
          color: '#596C88',
          position: 'absolute',
          left: '50px',
          top: '35px',
        }}
      >
        {'La dehesa'}
      </div>
    </div>
  );
  const bodyContentDemo = (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ fontSize: '12px', lineHeight: '19.27px', letterSpacing: '10%', margin: '10px 0' }}>CAJAS</div>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '5px 0' }}>
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '5px 0' }}>
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '5px 0' }}>
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
          <img src={CajaIcon} />
        </div>
      </div>
    </div>
  );
  return (
    <section className="transback-view">
      {/* <div className="toolbar">
        <img src={Logo} />
        <div className="title">{`Pago / Cajas clínica alemana (xCash - Transbank)`}</div>
      </div> */}
      <Toolbar title={`Pago / Cajas clínica alemana (xCash - Transbank)`} />
      <div className="content">
        <div className="column-items">
          <CardInfo
            status="NOTDEFINED"
            mainContent={headerCardDemoVitacura}
            bodyContent={bodyContentDemo}
            items={[<InfoItem status={'NOTDEFINED'} itemName={'APP'} itemLabel={'XCash'} key={'1'} />]}
          />
          <CardInfo
            status="NOTDEFINED"
            mainContent={headerCardDemoLaDehesa}
            bodyContent={bodyContentDemo}
            items={[<InfoItem status={'NOTDEFINED'} itemName={'APP'} itemLabel={'XCash'} key={'1'} />]}
          />
        </div>
        {orderColumns.map((order, index) => {
          const arrayDevicesName = order.split(',');
          const arrayDeviceCmpt = [];
          if (arrayDevicesName && arrayDevicesName.length) {
            arrayDevicesName.forEach((device) => {
              const arrayItemsCmpt = [];
              const infDev = infoDashboard[device];
              infDev.items.forEach((itm, index) => {
                arrayItemsCmpt.push(
                  <InfoItem
                    iconName={itm.iconName}
                    status={itm.status}
                    itemName={itm.name}
                    itemLabel={itm.label}
                    extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                    key={`item-${itm.name}-${itm.label}-${device}-${index}`}
                  />
                );
              });
              if (infDev.name === 'Maullinx.alemana.cl') {
                arrayItemsCmpt.push(
                  <InfoItem
                    iconName={'query'}
                    status={'NOTDEFINED'}
                    itemName={'Query 1'}
                    itemLabel={'Registro de pagos'}
                    key={`item-query1`}
                  />
                );
                arrayItemsCmpt.push(
                  <InfoItem
                    iconName={'query'}
                    status={'NOTDEFINED'}
                    itemName={'Query 2'}
                    itemLabel={'Registro de ventas'}
                    key={`item-query2`}
                  />
                );
                arrayItemsCmpt.push(
                  <InfoItem
                    iconName={'query'}
                    status={'NOTDEFINED'}
                    itemName={'Query 3'}
                    itemLabel={'Registro'}
                    key={`item-query3`}
                  />
                );
              }
              arrayDeviceCmpt.push(
                <CardInfo
                  status={infDev.status}
                  style={{
                    position:
                      infDev.name === 'Maullinx.alemana.cl' || infDev.name === 'Algarrobo0.alemana.cl'
                        ? 'absolute'
                        : '',
                    top: infDev.name === 'Maullinx.alemana.cl' ? '300px' : '97px',
                  }}
                  mainContent={
                    <InfoHost
                      iconName={infDev.iconName}
                      type={infDev.custom_desc ? infDev.custom_desc : infDev.type}
                      hostname={infDev.name}
                      ip={`IP ${infDev.ip}`}
                    />
                  }
                  items={arrayItemsCmpt}
                />
              );
            });
          } else {
            return null;
          }
          return (
            <>
              <div
                className="column-items"
                key={`column-${index}`}
                style={{ position: 'relative', width: '287px', height: '100%' }}
              >
                {arrayDeviceCmpt}
              </div>
            </>
          );
        })}
        <div className="column-items">
          <div
            style={{
              width: '198px',
              height: '171px',
              background: '#ffffff',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            }}
          >
            <div style={{ width: '161px', height: '64px' }}>
              <img src={LogoTransback} style={{ width: '100%', height: '100%' }} />
            </div>
            <div style={{ width: '59px' }}>
              <img src={ApiIcon} style={{ width: '100%', height: 'auto' }} />
            </div>
            <div style={{ fontSize: '12px', lineHeight: '19.26px' }}>API</div>
            <div style={{ fontSize: '12px', lineHeight: '19.26px' }}>Nombre 123</div>
          </div>
        </div>
        <div className="line" style={{ width: '222px', top: '151px', left: '287px' }}></div>
        <div
          style={{
            left: '390px',
            top: '140px',
            width: '25px',
            height: '25px',
            background: '#AEB0B2',
            borderRadius: '3px',
            position: 'absolute',
          }}
        ></div>
        <div className="top-arrow" style={{ width: '14px', top: '146px', left: '498px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '156px', left: '498px' }}></div>
        <div className="line" style={{ width: '111px', top: '537px', left: '287px' }}></div>
        <div className="line" style={{ width: '3px', height: '328px', top: '210px', left: '396px' }}></div>
        <div
          style={{
            left: '385px',
            top: '525px',
            width: '25px',
            height: '25px',
            background: '#AEB0B2',
            borderRadius: '3px',
            position: 'absolute',
          }}
        ></div>
        <div className="line" style={{ width: '114px', top: '207px', left: '396px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '202px', left: '499px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '212px', left: '499px' }}></div>
        <div className="line" style={{ width: '3px', height: '64px', top: '246px', left: '651px' }}></div>
        <div className="line" style={{ width: '223px', top: '150px', left: '797px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '145px', left: '1009px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '155px', left: '1009px' }}></div>
        <div className="line" style={{ width: '111px', top: '207px', left: '797px' }}></div>
        <div className="line" style={{ width: '3px', height: '330px', top: '210px', left: '906px' }}></div>
        <div className="line" style={{ width: '111px', top: '537px', left: '909px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '532px', left: '1009px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '542px', left: '1009px' }}></div>

        <div className="line" style={{ width: '111px', top: '200px', left: '1310px' }}></div>
        <div className="line" style={{ width: '3px', height: '130px', top: '200px', left: '1421px' }}></div>
        <div className="line" style={{ width: '111px', top: '330px', left: '1421px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '325px', left: '1521px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '335px', left: '1521px' }}></div>
        <div className="line" style={{ width: '111px', top: '550px', left: '1310px' }}></div>
        <div className="line" style={{ width: '3px', height: '130px', top: '422px', left: '1421px' }}></div>
        <div className="line" style={{ width: '111px', top: '422px', left: '1421px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '417px', left: '1521px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '427px', left: '1521px' }}></div>
      </div>
      <div className="back-icon" onClick={selectMainDashboard}>
        <div>
          <img src={icons['back']} />
        </div>
      </div>
    </section>
  );
}

export default Transbank;
