import React from 'react';
import './CardEnlace.css';

import { color_status } from '../../utils/dictionaryStates';
import { icons } from '../../utils/dictionaryIcons';

function CardEnlace({ status, mainContent = null, bodyContent = null, items, iconName, style, side }) {
  return (
    <section className="cardEnlace-cmpt" style={style}>
      {side === 'left' ? (
        <>
          <div style={{ display: 'flex' }}>
            <div className="main-info-left" style={{ borderBottom: bodyContent ? '1px solid #D0DCED' : null }}>
              <div className="main-content">
                <img src={icons[iconName]} />
              </div>
              <div className="status" style={{ background: color_status[status] }} />
            </div>
            <div className="items-info-left">{items}</div>
          </div>
          <div className="body-info-left">{bodyContent}</div>
        </>
      ) : (
        <>
          <div className="items-info">{items}</div>
          <div className="main-info" style={{ borderBottom: bodyContent ? '1px solid #D0DCED' : null }}>
            <div className="main-content">
              <img src={icons[iconName]} />
            </div>
            <div className="status" style={{ background: color_status[status] }} />
          </div>
          <div className="body-info">{bodyContent}</div>
        </>
      )}
    </section>
  );
}

export default CardEnlace;
