import React from 'react';
import './CardWrapper.css';

import { color_status } from '../../utils/dictionaryStates';

function CardWrapper({ status, mainContent, bodyContent = null, items, style }) {
  return (
    <section className="cardWrapper-cmpt" style={style}>
      <div className="main-info" style={{ borderBottom: bodyContent ? '1px solid #D0DCED' : null }}>
        <div className="main-content">{mainContent}</div>
        <div className="status" style={{ background: color_status[status] }} />
      </div>
      <div className="body-info">{bodyContent}</div>
      <div className="items-info">{items}</div>
    </section>
  );
}

export default CardWrapper;
