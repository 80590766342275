import React, { useEffect, useState } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import InfoItem from '../../components/InfoItem/InfoItem';
import CardInfo from '../../components/CardInfo/CardInfo';
import InfoHost from '../../components/InfoHost/InfoHost';
import InfoItemIndicadores from '../../components/InfoItemIndicadores/InfoItemIndicadores';
import Heatmap from '../../components/Heatmap/Heatmap';
import CardVmware from '../../components/CardVmware/CardVmware';

import { icons } from '../../utils/dictionaryIcons';

import './InfraCritica2.css';

function InfraCritica2({ infoDashboard, selectMainDashboard, tanzu }) {

    const [vcenterObject, setVcenterObject] = useState([]);
    const [weblogicObject, setWeblogicObject] = useState(null);
    const [ldapObject, setLdapObject] = useState([]);
    const [dynatrace, setDynatrace] = useState(null);

    function getColumn(orderColumns) {
        return orderColumns.map((order, index) => {
            const arrayDevicesName = order.split(',');
            const arrayDeviceCmpt = [];
            let isFlex1 = false;
            if (weblogicObject && arrayDevicesName && arrayDevicesName.length) {
                arrayDevicesName.forEach((device) => {
                    const arrayItemsCmpt = [];
                    const infDev = weblogicObject[device];
                    isFlex1 = infDev.name === 'SYS1' ? true : false;
                    infDev.items.forEach((itm, index) => {
                        arrayItemsCmpt.push(
                            <InfoItem
                                iconName={itm.iconName}
                                status={itm.status}
                                itemName={itm.name}
                                itemLabel={itm.label}
                                extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                                key={`item-${itm.name}-${itm.label}-${device}-${index}`}
                                style={{ padding: '2px' }}
                            />
                        );
                    });
                    arrayDeviceCmpt.push(
                        <CardInfo
                            status={infDev.status}
                            mainContent={
                                <InfoHost iconName={infDev.iconName} type={'Servidor'} hostname={infDev.name} ip={`IP ${infDev.ip}`} />
                            }
                            items={arrayItemsCmpt}
                        />
                    );
                });
            } else {
                return null;
            }
            return (
                <>
                    <div
                        className="column-items"
                        key={`column-${index}`}
                        style={{ width: '287px', height: '100%', flex: isFlex1 ? 1 : 2 }}
                    >
                        {arrayDeviceCmpt}
                    </div>
                </>
            );
        });
    }


    function getVcenter() {
        console.log("Data:", vcenterObject);
        const vcenterAltamar = [];
        const vcenterRest = [];

        const arrayVcenter = [];
        for (const vcenter in vcenterObject) {
            let type = 0;

            const vcenterInfo = vcenterObject[vcenter];
            const arrayVcenterItems = [];
            const arrayVcenterItemsAux = [];

            let size = undefined;
            if (vcenter !== 'altamar') {
                size = {col1: "62px", col2: "95px",col3: "46px",col4: "70px"} 
            }
            for (const vcenterHostname in vcenterInfo) {
                const vcenterHostInfo = vcenterInfo[vcenterHostname];
                if (vcenterHostname === 'status') {
                } else {
                    let temp = <CardVmware
                        host={{ name: vcenterHostname, status: vcenterHostInfo.status }}
                        uptime={{ status: vcenterHostInfo['Uptime'].status }}
                        overall_status={{ status: vcenterHostInfo['Overall_status'].status }}
                        cpu={{ status: vcenterHostInfo['CPU'].status }}
                        memory={{ status: vcenterHostInfo['Memory'].status }}
                        size = {size}
                    />
                    if(vcenterHostname.includes("tacora")){
                        arrayVcenterItemsAux.push(temp);
                    }else{
                        arrayVcenterItems.push(temp);
                        if(vcenterHostname.includes("carora")){
                            type = 1;
                        }
                    }

                }
            }
            if (vcenter === 'altamar') {
                var altamarCol1 = arrayVcenterItems.slice(0,arrayVcenterItems.length/2);
                var altamarCol2 = arrayVcenterItems.slice(arrayVcenterItems.length/2,-1);
                vcenterAltamar.push(
                    <>

                        <div className="vcenter-title">
                            <div style={{ fontWeight: 'bold' }}>{vcenter}</div>
                        </div>
                        <div className='wrapper-altamar'>
                            <div className='col-altamar'>
                                <div className="vcenter-items">{altamarCol1}</div>
                            </div>
                            <div className='col-altamar'>
                                <div className="vcenter-items">{altamarCol2}</div>
                            </div>
                        </div>

                    </>
                );
            } else {
                vcenterRest.push(
                    <div className="vcenter">
                        <div className="vcenter-title">
                            <div style={{ fontWeight: 'bold' }}>{vcenter}</div>
                        </div>
                        <div className="vcenter-title">
                            <div style={{ fontWeight: 'bold' }}>{arrayVcenter}</div>
                        </div>
                        <div className="vcenter-items lascar-items">
                            <div style={{ fontWeight: 'bold' }}>
                                {type ? "VDI-DT-EMO" : "VDI La Dehesa"}
                            </div>
                            {type ? arrayVcenterItems:  arrayVcenterItemsAux }  
                        </div>
                        <div className="vcenter-items lascar-items">
                            <div style={{ fontWeight: 'bold' }}>
                                {type ? "VDI-Vitacura" : "VxRail VDI"}
                            </div>
                            {type ? arrayVcenterItemsAux: arrayVcenterItems}                            
                        </div>
                    </div>
                );
            }
        }
        return (
            <div className="wrapper-vcenter2">
                {vcenterAltamar}
                <div className='wrapper-lascar'>{vcenterRest}</div>
            </div>
        );
    }

    function getDynatrace() {
        // console.log();
        const objDynatrace = dynatrace;
        const items = [];
        if (objDynatrace) {
            // console.log(objDynatrace['cpu'][0]);
            // console.log(objDynatrace['cpu'][0]['data']);
            items.push(
                <InfoItemIndicadores
                    status={'OK'}
                    itemName={`CPU - ${objDynatrace['cpu'][0]['data'][0]['values'][0].toFixed(2)}%`}
                    key={`cpu-dyna`}
                    style={{ width: '170px', height: '24px' }}
                />
            );
            items.push(
                <InfoItemIndicadores
                    status={'OK'}
                    itemName={`Memory - ${objDynatrace['memory'][0]['data'][0]['values'][0].toFixed(2)}%`}
                    key={`memory-dyna`}
                    style={{ width: '170px', height: '24px' }}
                />
            );
        }
        return (
            <div className='dyna-items'>
                {items}
            </div>
        );
    }

    function getLdap() {
        // console.log(ldapObject);
        const items = [];
        ldapObject.forEach((ldapItem, index) => {
            items.push(
                <InfoItemIndicadores
                    status={ldapItem.status}
                    itemName={ldapItem.service}
                    key={`${index}-ldap`}
                    style={{ width: '300px', height: '24px' }}
                />
            );
        });
        return (
            <div className='Ldap-items'>
                {items}
            </div>
        );
    }

    useEffect(() => {
        if (infoDashboard['vcenter']) {
            setVcenterObject(infoDashboard['vcenter']);
        }
        if (infoDashboard['weblogic']) {
            setWeblogicObject(infoDashboard['weblogic']);
        }
        if (infoDashboard['ldap']) {
            setLdapObject(infoDashboard['ldap']);
        }
        if (infoDashboard['dynatrace']) {
            setDynatrace(infoDashboard['dynatrace']);
        }
        //console.log(infoDashboard);
    }, [infoDashboard, tanzu]);


    return (
        <section className="infra-critica-view2">
            <Toolbar title={`Infraestructura Crítica`} />
            <div className='content'>
                <div className='vmware'>
                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Vmware</div>
                    {getVcenter()}
                </div>
                <div className="weblogic-dynatrace">
                    <div className='weblogic-section'>
                        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Weblogic</div>
                        {getColumn(['ANTOFAGASTA', 'TOCOPILLA'])}
                    </div>
                    <div className='dynatrace-section'>
                        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Tuxedo</div>
                        {getDynatrace()}
                        <div id="tooltip" className="tooltip"></div>
                        {<Heatmap tanzu={tanzu} />}
                    </div>
                    <div className='ldap-section'>
                        <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Ldap</div>
                        {getLdap()}
                    </div>
                </div>

            </div>
            <div className="back-icon" onClick={selectMainDashboard}>
                <div>
                    <img src={icons['back']} />
                </div>
            </div>
        </section>
    )
}

export default InfraCritica2;