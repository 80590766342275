import React, { useEffect, useState } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import CardVmware from '../../components/CardVmware/CardVmware';
import InfoItem from '../../components/InfoItem/InfoItem';
import CardInfo from '../../components/CardInfo/CardInfo';
import InfoHost from '../../components/InfoHost/InfoHost';
import InfoItemIndicadores from '../../components/InfoItemIndicadores/InfoItemIndicadores';
import Heatmap from '../../components/Heatmap/Heatmap';

import { icons } from '../../utils/dictionaryIcons';

import './InfraCritica.css';

function InfraCritica({ infoDashboard, selectMainDashboard, tanzu }) {
  console.log(tanzu);

  const [vcenterObject, setVcenterObject] = useState([]);
  const [weblogicObject, setWeblogicObject] = useState(null);
  const [ldapObject, setLdapObject] = useState([]);
  const [dynatrace, setDynatrace] = useState(null);
  // console.log(ldapObject);

  function getColumn(orderColumns) {
    return orderColumns.map((order, index) => {
      const arrayDevicesName = order.split(',');
      const arrayDeviceCmpt = [];
      let isFlex1 = false;
      if (weblogicObject && arrayDevicesName && arrayDevicesName.length) {
        arrayDevicesName.forEach((device) => {
          const arrayItemsCmpt = [];
          const infDev = weblogicObject[device];
          isFlex1 = infDev.name === 'SYS1' ? true : false;
          infDev.items.forEach((itm, index) => {
            arrayItemsCmpt.push(
              <InfoItem
                iconName={itm.iconName}
                status={itm.status}
                itemName={itm.name}
                itemLabel={itm.label}
                extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                key={`item-${itm.name}-${itm.label}-${device}-${index}`}
                style={{ padding: '2px' }}
              />
            );
          });
          arrayDeviceCmpt.push(
            <CardInfo
              status={infDev.status}
              mainContent={
                <InfoHost iconName={infDev.iconName} type={'Servidor'} hostname={infDev.name} ip={`IP ${infDev.ip}`} />
              }
              items={arrayItemsCmpt}
            />
          );
        });
      } else {
        return null;
      }
      return (
        <>
          <div
            className="column-items"
            key={`column-${index}`}
            style={{ width: '287px', height: '100%', flex: isFlex1 ? 1 : 2 }}
          >
            {arrayDeviceCmpt}
          </div>
        </>
      );
    });
  }

  function getVcenter() {
    // console.log(vcenterObject);
    const vcenterAltamar = [];
    const vcenterRest = [];
    // if () {

    // }

    const arrayVcenter = [];
    for (const vcenter in vcenterObject) {
      const vcenterInfo = vcenterObject[vcenter];
      const arrayVcenterItems = [];
      for (const vcenterHostname in vcenterInfo) {
        const vcenterHostInfo = vcenterInfo[vcenterHostname];
        if (vcenterHostname === 'status') {
        } else {
          // console.log(vcenterHostInfo);
          // console.log(vcenterHostname);
          arrayVcenterItems.push(
            <CardVmware
              host={{ name: vcenterHostname, status: vcenterHostInfo.status }}
              uptime={{ status: vcenterHostInfo['Uptime'].status }}
              overall_status={{ status: vcenterHostInfo['Overall_status'].status }}
              cpu={{ status: vcenterHostInfo['CPU'].status }}
              memory={{ status: vcenterHostInfo['Memory'].status }}
            />
          );
        }
      }
      // console.log(vcenter, vcenterInfo);
      if (vcenter === 'altamar') {
        vcenterAltamar.push(
          <div className="vcenter">
            <div className="vcenter-title">
              <div style={{ fontWeight: 'bold' }}>{vcenter}</div>
            </div>
            <div className="vcenter-items">{arrayVcenterItems}</div>
          </div>
        );
      } else {
        vcenterRest.push(
          <div className="vcenter">
            <div className="vcenter-title">
              <div style={{ fontWeight: 'bold' }}>{vcenter}</div>
            </div>
            <div className="vcenter-items">{arrayVcenterItems}</div>
          </div>
        );
      }
    }
    return (
      <div className="wrapper-vcenter">
        {vcenterAltamar}
        <div>{vcenterRest}</div>
      </div>
    );
  }

  function getLdap() {
    // console.log(ldapObject);
    const items = [];
    ldapObject.forEach((ldapItem, index) => {
      items.push(
        <InfoItemIndicadores
          status={ldapItem.status}
          itemName={ldapItem.service}
          key={`${index}-ldap`}
          style={{ width: '305px', height: '24px' }}
        />
      );
    });
    return items;
  }

  function getDynatrace() {
    // console.log();
    const objDynatrace = dynatrace;
    const items = [];
    if (objDynatrace) {
      // console.log(objDynatrace['cpu']);
      // console.log(objDynatrace['cpu'][0]);
      // console.log(objDynatrace['cpu'][0]['data']);
      items.push(
        <InfoItemIndicadores
          status={'OK'}
          itemName={`CPU - ${objDynatrace['cpu'][0]['data'][0]['values'][0].toFixed(2)}%`}
          key={`cpu-dyna`}
          style={{ width: '170px', height: '24px' }}
        />
      );
      items.push(
        <InfoItemIndicadores
          status={'OK'}
          itemName={`Memory - ${objDynatrace['memory'][0]['data'][0]['values'][0].toFixed(2)}%`}
          key={`memory-dyna`}
          style={{ width: '170px', height: '24px' }}
        />
      );
    }
    return items;
  }

  useEffect(() => {
    if (infoDashboard['vcenter']) {
      setVcenterObject(infoDashboard['vcenter']);
    }
    if (infoDashboard['weblogic']) {
      setWeblogicObject(infoDashboard['weblogic']);
    }
    if (infoDashboard['ldap']) {
      setLdapObject(infoDashboard['ldap']);
    }
    if (infoDashboard['dynatrace']) {
      setDynatrace(infoDashboard['dynatrace']);
    }
    console.log(infoDashboard);
  }, [infoDashboard, tanzu]);

  return (
    <section className="infra-critica-view">
      <Toolbar title={`Infraestructura Crítica`} />
      {/* <div className="content" style={{ width: '24px' }}> */}
      <div className="content">
        <div className="vmware">
          <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Vmware</div>
          {getVcenter()}
        </div>
        <div className="weblogic-dynatrace">
          <div className="weblogic-section">
            <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Weblogic</div>
            {getColumn(['ANTOFAGASTA', 'TOCOPILLA'])}
          </div>
          <div className="ldap-section">
            <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Ldap</div>
            {getLdap()}
          </div>
          <div className="dynatrace-section">
            <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Dynatrace</div>
            {getDynatrace()}
            <div id="tooltip" className="tooltip"></div> 
            {<Heatmap tanzu={tanzu} />}
          </div>
        </div>
        {/* <img src={icons['cpu']} /> */}
      </div>
      <div className="back-icon" onClick={selectMainDashboard}>
        <div>
          <img src={icons['back']} />
        </div>
      </div>
    </section>
  );
}

export default InfraCritica;
