import React from 'react';
import { color_status } from '../../utils/dictionaryStates';

import './AlemanaStatus.css';

function AlemanaStatus({ status, children }) {
  return (
    <section className="alemana-status-cmpt">
      <div className="body-content-asc">{children}</div>
      <div className="status-asc">
        <div className="indicator-st-asc" style={{ background: color_status[status] }}></div>
      </div>
    </section>
  );
}

export default AlemanaStatus;
