// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoenlace-cmpt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-family: Khula;
}

.infoenlace-cmpt .icon-infoenlace {
  width: 20%;
  height: 100%;
}
.infoenlace-cmpt .icon-infoenlace img {
  width: 28px;
  height: auto;
}

.infoenlace-cmpt .content-infoenlace {
  /* width: 80%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.infoenlace-cmpt .content-infoenlace .type {
  width: 90px;
  font-size: 10px;
  line-height: 19.26px;
  color: #596c88;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* position: absolute;
  top: -2px; */
}

.infoenlace-cmpt .content-infoenlace .hostname {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 25.67px;
  color: #596c88;
  position: absolute;
  top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoenlace-cmpt .content-infoenlace .ip {
  width: 100%;
  font-size: 11px;
  line-height: 14.44px;
  color: #03c4c4;
  /* position: absolute; */
  top: 18px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./components/InfoEnlace/InfoEnlace.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB;cACY;AACd;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,wBAAwB;EACxB,SAAS;EACT,iBAAiB;AACnB","sourcesContent":[".infoenlace-cmpt {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n  font-family: Khula;\n}\n\n.infoenlace-cmpt .icon-infoenlace {\n  width: 20%;\n  height: 100%;\n}\n.infoenlace-cmpt .icon-infoenlace img {\n  width: 28px;\n  height: auto;\n}\n\n.infoenlace-cmpt .content-infoenlace {\n  /* width: 80%; */\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n\n.infoenlace-cmpt .content-infoenlace .type {\n  width: 90px;\n  font-size: 10px;\n  line-height: 19.26px;\n  color: #596c88;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  /* position: absolute;\n  top: -2px; */\n}\n\n.infoenlace-cmpt .content-infoenlace .hostname {\n  width: 100%;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 25.67px;\n  color: #596c88;\n  position: absolute;\n  top: 8px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.infoenlace-cmpt .content-infoenlace .ip {\n  width: 100%;\n  font-size: 11px;\n  line-height: 14.44px;\n  color: #03c4c4;\n  /* position: absolute; */\n  top: 18px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
