import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import {
  processData,
  createDataForDashboard,
  createDataForEnlacesDashboard,
  createDataForInfraCriticaDashboard,
  getClientesIndicadores,
  getClientesIndicadoresSubscriptors,
  appsByNucleoErrors
  // @ts-ignore
} from 'api/datasource.js';
// @ts-ignore
import Transbank from '../views/Transbank/Transbank';
// @ts-ignore
import NewTransbank from '../views/NewTransbank/NewTransbank';
// @ts-ignore
import InfroCritica from '../views/InfraCritica/InfraCritica';
// @ts-ignore
import InfraCritica2 from '../views/InfraCritica2/InfraCritica2';
// @ts-ignore
import ClientesIndicadores from '../views/ClientesIndicadores/ClientesIndicadores';
// @ts-ignore
import Enlaces from '../views/Enlaces/Enlaces';
// @ts-ignore
import Central from '../views/Central/Central';
// @ts-ignore
import Nucleo from '../views/Nucleo/Nucleo';
// @ts-ignore
import array_of_central_items from './fake.js';

interface Props extends PanelProps<SimpleOptions> {
  options: any;
  data: any;
  width: number;
  height: number;
}

export const SimplePanel: React.FC<Props> = ({ options, data }) => {
  const [infoCajasDashboard, setInfoCajasDashboard] = useState({});
  const [infoEnlacesDashboard, setInfoEnlacesDashboard] = useState({});
  const [infoTransbankDashboard, setInfoTransbankDashboard] = useState({});
  const [infoInfraCriticaDashboard, setInfoInfraCriticaDashboard] = useState({});
  const [infoNucleoDashboard, setInfoNucleoDashboard] = useState({});
  const [completeSeries, setCompleteSeries] = useState(null);
  const [subscriptoresClientesIndicadores, setSubscriptoresClientesIndicadores] = useState({});

  const [ldapStatus, setLdapStatus] = useState(4);
  // const [infoHostsWeblogic, setInfoHostsWeblogic] = useState({});

  const [orderColumns, setOrderColumns] = useState([]);
  const [speedEnlaces, setEnlacesSpeed] = useState([]);
  const [thresholdSpeed, setThresholdsSpeed] = useState({ overloaded: 85, unused: 5 });
  // const [nameSelectedDashboard, setNameSelectedDashboard] = useState('Clientes indicadores');
  const [nameSelectedDashboard, setNameSelectedDashboard] = useState('');
  const [arrayCentralItems, setArrayCentralItems] = useState(array_of_central_items);
  const [infoIndicatorClients, setInfoIndicatorClients] = useState(null);
  const [infraStatusTmp, setInfraStatusTmp] = useState({ total: 0, problems: 0, status: 'UNDEFINED' });

  const [tanzu, setTanzu] = useState([]);

  function selectDashboard(dashboard: any) {
    setNameSelectedDashboard(dashboard);
  }
  function getDashboard() {
    if (nameSelectedDashboard === 'Cajas (xCash / Transbank)') {
      return (
        <Transbank
          infoDashboard={infoCajasDashboard}
          orderColumns={orderColumns}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Transbank') {
      return (
        <NewTransbank
          infoDashboard={infoTransbankDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else if (nameSelectedDashboard === 'Infraestructura Crítica') {
      return (
        <InfraCritica2
          infoDashboard={infoInfraCriticaDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          tanzu={tanzu}
        />
      );
    // } else if(nameSelectedDashboard === 'Infraestructura Crítica 2'){
    //   return (
    //     <InfraCritica2
    //       infoDashboard={infoInfraCriticaDashboard}
    //       selectMainDashboard={() => {
    //         selectDashboard(null);
    //       }}
    //       tanzu={tanzu}
    //     />
    //   );
    }else if (nameSelectedDashboard === 'Clientes indicadores') {
      return (
        <ClientesIndicadores
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          ldapStatus={ldapStatus}
          tanzu={tanzu}
          infoIndicatorClients={infoIndicatorClients}
          subscriptoresClientesIndicadores={subscriptoresClientesIndicadores}
        />
      );
    } else if (nameSelectedDashboard === 'Enlaces') {
      return (
        <Enlaces
          speedEnlaces={speedEnlaces}
          infoDashboard={infoEnlacesDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
          overloadedSpeed={thresholdSpeed['overloaded']}
          unusedSpeed={thresholdSpeed['unused']}
        />
      );
    } else if (nameSelectedDashboard === 'Nucleo') {
      return (
        <Nucleo
          infoDashboard={infoNucleoDashboard}
          selectMainDashboard={() => {
            selectDashboard(null);
          }}
        />
      );
    } else {
      return <Central array_of_central_items={arrayCentralItems} selectDashboard={selectDashboard} />;
    }
  }

  // @ts-ignore
  function removeCircularReferences(obj, seen = new WeakSet()) {
    if (obj && typeof obj === 'object') {
      if (seen.has(obj)) {
        return '[Circular]';
      }
      seen.add(obj);
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = removeCircularReferences(obj[key], seen);
        }
      }
      seen.delete(obj);
    }
    return obj;
  }

  async function getInfraCritica() {
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');
    // const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
    // const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');

    const series = JSON.parse(JSON.stringify(originalSeries));
    const {
      vcenter,
      infoHostsWeblogic: weblogic,
      ldap,
      dynatrace,
      infraStatus,
      totalInfra,
      problemInfra,
      ldapStatus,
      tanzu,
    } = await createDataForInfraCriticaDashboard(series['infracritica']);
    // const res =  await appsByNucleoErrors(series['infracritica']);
    // console.log(res);
    setInfraStatusTmp({ total: totalInfra, problems: problemInfra, status: infraStatus });
    // copyArrayCentralItems[indexInfraCrit]['status'] = infraStatus;
    // copyArrayCentralItems[indexInfraCrit]['total'] = totalInfra;
    // copyArrayCentralItems[indexInfraCrit]['problems'] = problemInfra;
    setInfoInfraCriticaDashboard({ vcenter, weblogic, ldap, dynatrace });
    // setArrayCentralItems(copyArrayCentralItems);
    setLdapStatus(ldapStatus);
    setTanzu(tanzu);
  }
  // @ts-ignore
  useEffect(() => {
    getInfraCritica();
    // @ts-ignore
  }, [data]);
  // console.log(arrayCentralItems);

  async function getSubscriptorsClientesIndicadores() {
    if (completeSeries && completeSeries['infracritica']) {
      const infraResponse = await getClientesIndicadoresSubscriptors(completeSeries['infracritica']);
      setSubscriptoresClientesIndicadores(infraResponse);
      // console.log(infraResponse);
    }
  }

  useEffect(() => {
    const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');
    const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
    const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
    copyArrayCentralItems[indexInfraCrit]['status'] = infraStatusTmp.status;
    copyArrayCentralItems[indexInfraCrit]['total'] = infraStatusTmp.total;
    copyArrayCentralItems[indexInfraCrit]['problems'] = infraStatusTmp.problems;
   
    if (copyArrayCentralItems[indexClientesIndicadores]['status'] === 'OK' && infraStatusTmp.status === 'CRITICAL') {
      copyArrayCentralItems[indexClientesIndicadores]['status'] = infraStatusTmp.status;
    }
    setArrayCentralItems(copyArrayCentralItems);
    getSubscriptorsClientesIndicadores();
  }, [infraStatusTmp]);

  // async function configClientIndicators() {
  //   const infraResponse = await getClientesIndicadores(series['clientesindicadores'], series['infracritica']);
  //   const oracleInfo = infraResponse['oracle'];
  //   const infraStatus = infraResponse['globalStatus'];
  //   let infoIndicators = { oracleInfo: oracleInfo };
  //   const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
  //   copyArrayCentralItems[indexClientesIndicadores]['status'] = infraStatus;
  //   // @ts-ignore
  //   setInfoIndicatorClients(infoIndicators);
  // }

  // @ts-ignore
  useEffect(() => {
    const cleanedData = removeCircularReferences(data);
    const dataToCreateInfo = JSON.parse(JSON.stringify(cleanedData));
    const originalSeries = processData(dataToCreateInfo, '*', 'json');

    const series = JSON.parse(JSON.stringify(originalSeries));
    setCompleteSeries(series);
    const {
      infoHosts: infoTmpDashboard,
      globalStatus: cajasGlobalStatus,
      total,
      problems,
    } = createDataForDashboard(series['cajas']);

    const {
      infoHosts: infoEnlacesDashboard,
      globalStatus: enlacesGlobalStatus,
      total: totalEnlaces,
      problems: problemsEnlaces,
    } = createDataForEnlacesDashboard(series['enlaces']);

    const {
      infoHosts: transbankInfoDashboard,
      globalStatus: transbankGlobalStatus,
      total: transbankTotal,
      problems: transbankProblems,
    } = createDataForDashboard(series['transbank']);
    const {
      infoHosts: nucleoInfoDashboard,
      globalStatus: nucleoGlobalStatus,
      total: nucleoTotal,
      problems: nucleoProblems,
    } = createDataForDashboard(series['nucleo'], 'OK');
    // console.log(series['nucleo']);
    // console.log(nucleoInfoDashboard);
    // console.log(nucleoGlobalStatus);
    // console.log(nucleoTotal);
    // console.log(nucleoProblems);
    
    

    // const { oracle: oracleInfo, globalStatus: infraStatus } = getClientesIndicadores(series['clientesindicadores'], series['infracritica']);
    const infraResponse = getClientesIndicadores(series['clientesindicadores'], series['infracritica']);
    const oracleInfo = infraResponse['oracle'];
    const infraStatus = infraResponse['globalStatus'];
    // console.log(infraResponse);
    
    // const {
    //   vcenter,
    //   infoHostsWeblogic: weblogic,
    //   ldap,
    //   dynatrace,
    //   infraStatus,
    //   totalInfra,
    //   problemInfra,
    //   ldapStatus,
    // } = await createDataForInfraCriticaDashboard(series['infracritica']);
    // console.log(vcenter);
    const indexCajas = arrayCentralItems.findIndex((itm: any) => itm.title === 'Cajas (xCash / Transbank)');
    const indexEnlaces = arrayCentralItems.findIndex((itm: any) => itm.title === 'Enlaces');
    const indexTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Transbank');
    const nucleoTransbank = arrayCentralItems.findIndex((itm: any) => itm.title === 'Nucleo');
    const indexClientesIndicadores = arrayCentralItems.findIndex((itm: any) => itm.title === 'Clientes indicadores');
    // const indexInfraCrit = arrayCentralItems.findIndex((itm: any) => itm.title === 'Infraestructura Crítica');

    const copyArrayCentralItems = JSON.parse(JSON.stringify(arrayCentralItems));
    // console.log(copyArrayCentralItems);
    // console.log(nucleoGlobalStatus);

    // Clientes indicadores
    // console.log(oracleInfo);

    // console.log(indexClientesIndicadores);
    
    copyArrayCentralItems[indexClientesIndicadores]['status'] = infraStatus;
    // copyArrayCentralItems[indexClientesIndicadores]['total'] = nucleoTotal;
    // copyArrayCentralItems[indexClientesIndicadores]['problems'] = nucleoProblems;
    // Cajas
    copyArrayCentralItems[nucleoTransbank]['status'] = nucleoGlobalStatus;
    copyArrayCentralItems[nucleoTransbank]['total'] = nucleoTotal;
    copyArrayCentralItems[nucleoTransbank]['problems'] = nucleoProblems;

    // Cajas
    copyArrayCentralItems[indexCajas]['status'] = cajasGlobalStatus;
    copyArrayCentralItems[indexCajas]['total'] = total;
    copyArrayCentralItems[indexCajas]['problems'] = problems;
    setInfoCajasDashboard(infoTmpDashboard);

    // Enlaces
    copyArrayCentralItems[indexEnlaces]['status'] = enlacesGlobalStatus;
    copyArrayCentralItems[indexEnlaces]['total'] = totalEnlaces;
    copyArrayCentralItems[indexEnlaces]['problems'] = problemsEnlaces;
    setInfoEnlacesDashboard(infoEnlacesDashboard);

    // Transbank
    copyArrayCentralItems[indexTransbank]['status'] = transbankGlobalStatus;
    copyArrayCentralItems[indexTransbank]['total'] = transbankTotal;
    copyArrayCentralItems[indexTransbank]['problems'] = transbankProblems;
    setInfoTransbankDashboard(transbankInfoDashboard);

    // InfraCritica
    // copyArrayCentralItems[indexInfraCrit]['status'] = infraStatus;
    // copyArrayCentralItems[indexInfraCrit]['total'] = totalInfra;
    // copyArrayCentralItems[indexInfraCrit]['problems'] = problemInfra;
    // setInfoInfraCriticaDashboard({ vcenter, weblogic, ldap, dynatrace });
    // setLdapStatus(ldapStatus);
    // setInfoHostsWeblogic();

    // Set info central panel
    setArrayCentralItems(copyArrayCentralItems);

    setInfoNucleoDashboard(nucleoInfoDashboard);
    // ORACLE
    let infoIndicators = { oracleInfo: oracleInfo };
    // @ts-ignore
    setInfoIndicatorClients(infoIndicators);
  }, [data]);

  useEffect(() => {
    if (options && options.orderColumns) {
      setOrderColumns(JSON.parse(options.orderColumns));
    }
    if (options && options.enlacesSpeed) {
      setEnlacesSpeed(JSON.parse(options.enlacesSpeed));
    }
    if (options && options['enlace-critical-threshold'] && options['enlace-unused-threshold']) {
      setThresholdsSpeed({
        overloaded: options['enlace-critical-threshold'],
        unused: options['enlace-unused-threshold'],
      });
    }
  }, [options]);

  return (
    <div
      className="panel-wrapper"
      style={{ width: '1800px', height: '1020px', backgroundColor: '#d1def0', padding: '15px' }}
    >
      {getDashboard()}
    </div>
  );
};
