import React from 'react';
// import { icons } from '../../utils/dictionaryIcons';
import './InfoEnlace.css';

function InfoEnlace({ iconName, type, hostname, ip }) {
  return (
    <section className="infoenlace-cmpt">
      {/* <div className="icon-infoenlace">
        <img src={icons[iconName]} />
      </div> */}
      <div className="content-infoenlace">
        <div className="type">{type}</div>
        {/* <div className="hostname">{hostname}</div> */}
        <div className="ip">{ip}</div>
      </div>
    </section>
  );
}

export default InfoEnlace;
