import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder.addTextInput({
    path: 'orderColumns',
    name: 'Columns by type',
    description: 'Array with the order of columns',
    defaultValue: 'array',
  }).addTextInput({
    path: 'enlacesSpeed',
    name: 'Speed by enlace',
    description: 'Array with the speed of the enlace',
    defaultValue: "[]",
  }).addTextInput({
    path: 'enlace-critical-threshold',
    name: 'Overloaded threshold',
    description: 'Overloaded enlace speed',
    defaultValue: "85",
  }).addTextInput({
    path: 'enlace-unused-threshold',
    name: 'Unused threshold',
    description: 'Unused enlace speed',
    defaultValue: "5",
  });
  // return builder.addTextInput({
  //   path: 'enlacesSpeed',
  //   name: 'Speed by enlace',
  //   description: 'Array with the speed of the enlace',
  //   defaultValue: "[]",
  // });
});
