
export function getStatusColorByType(status: String) {
  if (status === 'OK') {
    return 'rgb(12, 216, 8)';
  } else if (status === 'WARNING' || status === 'WARN') {
    return '#E9B500';
  } else if (status === 'CRITICAL' || status === 'CRIT') {
    return '#D93747';
  } else if (status === 'UNKNOWN' || status === 'UNK') {
    return 'gray';
  } else if (status === 'OVERLOADED' || status === 'UNK') {
    return 'purple';
  } else if (status === 'NOLOADED' || status === 'UNK') {
    return 'gray';
  }
  return '#E9B500';
}

export function getStatusHoverColorByType(status: String) {
  if (status === 'OK') {
    return 'rgba(0, 209, 121, 0.2)';
  } else if (status === 'WARNING' || status === 'WARN') {
    return 'rgba(233, 181, 0, 0.2)';
  } else if (status === 'CRITICAL' || status === 'CRIT') {
    return 'rgba(217, 55, 71, 0.2)';
  }
  return 'rgba(233, 181, 0, 0.2)';
}

export function getStatusColorByThreshold(value: number, threshold = [75, 80]) {
  if (value >= threshold[1]) {
    return '#D93747';
  } else if (value >= threshold[0]) {
    return '#E9B500';
  } else {
    return 'rgb(12, 216, 8)';
  }
}

export function getStatusColorByNumber(status: any) {
  if (status === 0 || status === '0') {
    return 'rgb(12, 216, 8)';
  } else if (status === 1 || status === '1') {
    return '#E9B500';
  } else if (status === 2 || status === '2') {
    return '#D93747';
  }
  return 'gray';
}
