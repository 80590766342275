import React from 'react';
import './CardCentral.css';
import { icons } from '../../utils/dictionaryIcons';
import { color_status } from '../../utils/dictionaryStates';

function CardCentral({ label, iconName, status, selectDashboard, total, problems }) {
  return (
    <section className="card-central-cmpt" onClick={() => selectDashboard(label)}>
      <div className="info-content">
        <div className="info-content-text">
          <div className="image-central-content">
            <img src={icons[iconName]} />
          </div>
          <div className="label-content">{label}</div>
        </div>
        <div className="info-content-status" style={{ backgroundColor: color_status[status] }}></div>
      </div>
      <div className="info-result">
        <div className='label-state'>ESTADOS CON PROBLEMAS</div>
        <div className='label-count'>{`${problems} / ${total}`}</div>
      </div>
    </section>
  );
}

export default CardCentral;
