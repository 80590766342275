import React from 'react';
import { icons } from '../../utils/dictionaryIcons';

import './CardTransbank.css';

function CardTransbank({ title, label, iconName, children }) {
  return (
    <section className="card-transbank-cmpt">
      <div className="header">
        <div className="icon">
          <img src={icons[iconName]} />
        </div>
        <div className="header-text">
          <div className="title">{title}</div>
          <div className="label-cd-tr">{label}</div>
        </div>
      </div>
      <div className="list-items">{children}</div>
    </section>
  );
}

export default CardTransbank;
