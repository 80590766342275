import React from 'react';
import { icons } from '../../utils/dictionaryIcons';
import { color_status } from '../../utils/dictionaryStates';
import './InfoItemIndicadores.css';

function InfoItemIndicadores({ iconName, status, itemName, itemLabel, extraLabel, style }) {
  return (
    <section className="infoItemIndicadores-cmpt" style={style}>
      {iconName ? (
        <div className="icon-infoitemIndicadores">
          <img src={icons[iconName]} />
        </div>
      ) : null}
      <div className="content-infoitemIndicadores">
        <div className="name-infoitemIndicadores" style={{ top: itemLabel ? '-4px' : '4px' }}>
          {itemName}
        </div>
        {/* <div className="label-infoitemIndicadores">{itemLabel}</div> */}
        {/* {extraLabel ? <div className="extralabel-infoitemIndicadores">{extraLabel}</div> : null} */}
      </div>
      <div
        className="status-infoitemIndicadores"
        style={{ background: color_status[status], margin: extraLabel ? '8px' : '0px' }}
      />
    </section>
  );
}

export default InfoItemIndicadores;
