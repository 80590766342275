import React from 'react';
import { icons } from '../../utils/dictionaryIcons';
import { color_status } from '../../utils/dictionaryStates';
import './InfoItem.css';

function InfoItem({ iconName, status, itemName, itemLabel, extraLabel, style }) {
  return (
    <section className="infoItem-cmpt" style={style}>
      {iconName ? (
        <div className="icon-infoitem">
          <img src={icons[iconName]} />
        </div>
      ) : null}
      {/* <div className="icon-infoitem">
        <img src={icons[iconName]} />
      </div> */}
      <div className="content-infoitem">
        <div className="name-infoitem" style={{ top: itemLabel ? '-4px' : '4px' }}>
          {itemName}
        </div>
        <div className="label-infoitem">{itemLabel}</div>
        {extraLabel ? <div className="extralabel-infoitem">{extraLabel}</div> : null}
      </div>
      <div
        className="status-infoitem"
        style={{ background: color_status[status], margin: extraLabel ? '8px' : '0px' }}
      />
    </section>
  );
}

export default InfoItem;
