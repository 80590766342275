import React from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import CardCentral from '../../components/CardCentral/CardCentral';
import './Central.css';

function Central({ array_of_central_items, selectDashboard }) {
  return (
    <section className="central-view">
      <Toolbar title={'Estado de aplicaciones criticas'} />
      <section className="content-central">
        {array_of_central_items.map((card, index) => (
          <CardCentral
            key={`content-central-card-${index}`}
            label={card.title}
            iconName={card.icon}
            status={card.status}
            selectDashboard={selectDashboard}
            problems={card.problems}
            total={card.total}
          />
        ))}
      </section>
    </section>
  );
}

export default Central;
