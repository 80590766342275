// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Khula:regular,bold,italic&subset=latin,latin-ext);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-transback-view {
  width: 100%;
  height: 100%;
  background-color: #f2f6fe;
  border-radius: 30px;
  padding: 20px;
  color: #596c88;
  position: relative;
}

.new-transback-view .content {
  width: 100%;
  height: calc(100% - 76px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.new-transback-view .content .column-items {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.new-transback-view .content .line {
  position: absolute;
  background-color: #83a2cd;
  height: 3px;
}
.new-transback-view .content .top-arrow {
  position: absolute;
  transform: rotate(45deg);
  background-color: #83a2cd;
  height: 3px;
}

.new-transback-view .content .bottom-arrow {
  position: absolute;
  transform: rotate(-45deg);
  background-color: #83a2cd;
  height: 3px;
}

.new-transback-view .back-icon {
  position: absolute;
  right: 50px;
  top: 50px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.new-transback-view .back-icon img {
  width: 40px;
}
`, "",{"version":3,"sources":["webpack://./views/NewTransbank/NewTransbank.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Khula:regular,bold,italic&subset=latin,latin-ext');\n\n.new-transback-view {\n  width: 100%;\n  height: 100%;\n  background-color: #f2f6fe;\n  border-radius: 30px;\n  padding: 20px;\n  color: #596c88;\n  position: relative;\n}\n\n.new-transback-view .content {\n  width: 100%;\n  height: calc(100% - 76px);\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  position: relative;\n}\n\n.new-transback-view .content .column-items {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n}\n\n.new-transback-view .content .line {\n  position: absolute;\n  background-color: #83a2cd;\n  height: 3px;\n}\n.new-transback-view .content .top-arrow {\n  position: absolute;\n  transform: rotate(45deg);\n  background-color: #83a2cd;\n  height: 3px;\n}\n\n.new-transback-view .content .bottom-arrow {\n  position: absolute;\n  transform: rotate(-45deg);\n  background-color: #83a2cd;\n  height: 3px;\n}\n\n.new-transback-view .back-icon {\n  position: absolute;\n  right: 50px;\n  top: 50px;\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n}\n\n.new-transback-view .back-icon img {\n  width: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
