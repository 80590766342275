const nameSpaceReplicaCliente = 'cas-replica-cliente';

const arrayReplicaClienteApps = ['client-nats-01', 'integracion-clientes-fase1', 'client-nats-jetstream'];

const nameSpaceTuoTempoTemuco = 'cat-tuotempo:CLOUD_APPLICATION_NAMESPACE-6931AA9662EAC8B9';

const nameSpaceTuoTempoValdivia = 'cav-tuotempo:CLOUD_APPLICATION_NAMESPACE-84A2A8094B153C36';

const nameSpaceSalesforce = 'cas-salesforce:CLOUD_APPLICATION_NAMESPACE-8246B6B90045100E';

const nameSpaceApiClientes = 'cas-api-clientes';

const arrayApiClientes = ['apicli-operaciones-backend', 'cli-consultaidcliente', 'apijur-backend'];

const nameSpaceApiParam = 'cas-apiparam';

const arrayApiParam = ['apiparam-backend'];

const nameSpaceClientesV4 = 'cas-clientesv4';

const arrayClientesV4 = ['clientesv4-backend'];

// const nameSpaceApijur = 'apijur-backend';

export {
  nameSpaceReplicaCliente,
  arrayReplicaClienteApps,
  nameSpaceTuoTempoTemuco,
  nameSpaceTuoTempoValdivia,
  nameSpaceSalesforce,
  nameSpaceApiClientes,
  arrayApiClientes,
  nameSpaceApiParam,
  arrayApiParam,
  nameSpaceClientesV4,
  arrayClientesV4
};
