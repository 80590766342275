import React from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import CardInfoTransbank from '../../components/CardInfoTransbank/CardInfoTransbank';
import InfoItem from '../../components/InfoItem/InfoItem';
import InfoHost from '../../components/InfoHost/InfoHost';
import { icons } from '../../utils/dictionaryIcons';
import CardTransbank from '../../components/CardTransbank/CardTransbank';
import CardInfo from '../../components/CardInfo/CardInfo';
import './Nucleo.css';

function Nucleo({ infoDashboard, selectMainDashboard }) {
  const firstColumn = [
    {
      title: 'Active Directory',
      label: 'Login',
      iconName: 'servers',
      servers: [{ name: 'CHINA', services: ['Monitor ADCAS3'] }],
      horizontal: true,
    },
    {
      title: 'Proxy Apache',
      label: 'Antillanca',
      iconName: 'servers',
      servers: [{ name: 'ANTILLANCA', services: ['Apache: Number of processes running'] }],
      horizontal: true,
    },

    {
      title: 'HA Proxy',
      label: 'WS HIBA',
      iconName: 'servers',
      servers: [{ name: 'WSHIBA', services: ['ProxySwitcher Hiba'] }],

      horizontal: true,
    },
  ];
  const secondColumn = [
    {
      title: 'Cloverleaf',
      label: 'Cloverleaf',
      iconName: 'servers',
      servers: [{ name: 'HEIMDAL', services: ['Nucleo imágenes', 'Nucleo Modulab'] }],
      horizontal: true,
    },
    {
      title: 'Cluster Weblogic',
      label: 'Weblogic',
      iconName: 'servers',
      servers: [
        { name: 'CHINA', services: ['Nucleo'] },
        { name: 'CHINA', services: ['Integraciones'] },
        { name: 'antofagasta06', services: ['Weblogic JVM'] },
        { name: 'calama06', services: ['Weblogic JVM'] },
        { name: 'tocopilla06', services: ['Weblogic JVM'] },
      ],
      horizontal: true,
    },
    {
      title: 'AWS - HIBA',
      label: 'HIBA',
      iconName: 'servers',
      // servers: ['antofagasta06-1', 'calama06-1', 'tocopilla06-1'],
      servers: [{ name: 'WSHIBA', services: ['Chequeo URL Web Service  {HOST.NAME}'] }],

      horizontal: true,
    },
  ];
  const thirdColumn = [
    {
      title: 'BD Oracle',
      label: 'Primaria',
      iconName: 'servers',
      servers: [
        { name: 'EXA_PO11S7', services: ['Sesiones Activas Cluster', 'Queries mayor de 180 s'] },
        { name: 'CHINA', services: ['Datasource-NucleoDG', 'Datasource-NucleoDG_cluster06'] },
      ],
      horizontal: true,
    },
    {
      title: 'Tanzu',
      label: 'K8s',
      iconName: 'servers',
      servers: [{ name: 'NUCLEO_DIGITAL', services: ['Servicios Caidos'] }],
      horizontal: true,
    },
  ];

  function getFirstColumn() {
    console.log({ infoDashboard });
    const arrayCard = [];
    firstColumn.map((itemColumn, indexCard) => {
      const arrayDeviceCmpt = [];
      itemColumn.servers.map((server, indexServer) => {
        const arrayItemsCmpt = [];
        const infDev = infoDashboard[server.name];
        if (!infDev) {
          console.log('Server ' + server.name + ' not found');
        }
        if (infDev && infDev.items && infDev.items.length) {
          infDev.items.forEach((itm, index) => {
            if (server.services.includes(itm.name)) {
              arrayItemsCmpt.push(
                <InfoItem
                  style={{ marginTop: '0px', padding: '4px 10px' }}
                  iconName={itm.iconName}
                  status={itm.status}
                  itemName={itm.name}
                  itemLabel={itm.label}
                  extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                  // key={`item-${itm.name}-${itm.label}-${device}-${index}`}
                />
              );
            }
          });
        }
        arrayDeviceCmpt.push(
          <CardInfoTransbank
            status={infDev.status}
            mainContent={
              <InfoHost
                iconName={infDev.iconName}
                type={infDev.custom_desc ? infDev.custom_desc : infDev.type}
                hostname={infDev.name ? infDev.name : server.name}
                ip={infDev.ip ? `IP ${infDev.ip}` : ''}
              />
            }
            items={arrayItemsCmpt}
            key={`${server}-${indexServer}-first`}
          />
        );
      });

      arrayCard.push(
        <CardTransbank title={itemColumn.title} label={itemColumn.label} iconName={itemColumn.iconName}>
          {arrayDeviceCmpt}
        </CardTransbank>
      );
    });
    return (
      <div className="column-items" key={`transbank-first-column`} style={{ flex: '2' }}>
        {arrayCard}
      </div>
    );
  }

  function getSecondColumn() {
    const arrayCard = [];
    secondColumn.map((itemColumn, indexCard) => {
      const arrayDeviceCmpt = [];
      itemColumn.servers.map((server, indexServer) => {
        const arrayItemsCmpt = [];
        const infDev = infoDashboard[server.name];

        if (infDev && infDev.items && infDev.items.length) {
          infDev.items.forEach((itm, index) => {
            if (server.services.includes(itm.name)) {
              arrayItemsCmpt.push(
                <InfoItem
                  style={{ marginTop: '0px', padding: '4px 10px' }}
                  iconName={itm.iconName}
                  status={itm.status}
                  itemName={itm.name}
                  itemLabel={itm.label}
                  extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                  // key={`item-${itm.name}-${itm.label}-${device}-${index}`}
                />
              );
            }
          });
        }
        arrayDeviceCmpt.push(
          <CardInfoTransbank
            status={infDev.status}
            mainContent={
              <InfoHost
                iconName={infDev.iconName}
                type={infDev.custom_desc ? infDev.custom_desc : infDev.type}
                hostname={infDev.name ? infDev.name : server.name}
                ip={infDev.ip ? `IP ${infDev.ip}` : ''}
              />
            }
            items={arrayItemsCmpt}
            key={`${server}-${indexServer}-first`}
          />
        );
      });

      arrayCard.push(
        <CardTransbank title={itemColumn.title} label={itemColumn.label} iconName={itemColumn.iconName}>
          {arrayDeviceCmpt}
        </CardTransbank>
      );
    });
    return (
      <div className="column-items" key={`transbank-first-column`} style={{ flex: '2' }}>
        {arrayCard}
      </div>
    );
  }

  function getThirdColumn() {
    const arrayCard = [];
    thirdColumn.map((itemColumn, indexCard) => {
      const arrayDeviceCmpt = [];
      itemColumn.servers.map((server, indexServer) => {
        const arrayItemsCmpt = [];
        const infDev = infoDashboard[server.name];
        if (!infDev) {
          console.log(`Server ${server.name} not found`);
        }
        if (infDev && infDev.items && infDev.items.length) {
          infDev.items.forEach((itm, index) => {
            if (server.services.includes(itm.name)) {
              arrayItemsCmpt.push(
                <InfoItem
                  style={{ marginTop: '0px', padding: '4px 10px' }}
                  iconName={itm.iconName}
                  status={itm.status}
                  itemName={itm.name}
                  itemLabel={itm.label}
                  extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                  // key={`item-${itm.name}-${itm.label}-${device}-${index}`}
                />
              );
            }
          });
        }
        arrayDeviceCmpt.push(
          <CardInfoTransbank
            status={infDev.status}
            mainContent={
              <InfoHost
                iconName={infDev.iconName}
                type={infDev.custom_desc ? infDev.custom_desc : infDev.type}
                hostname={infDev.name ? infDev.name : server.name}
                ip={infDev.ip ? `IP ${infDev.ip}` : ''}
              />
            }
            items={arrayItemsCmpt}
            key={`${server}-${indexServer}-first`}
          />
        );
      });

      arrayCard.push(
        <CardTransbank title={itemColumn.title} label={itemColumn.label} iconName={itemColumn.iconName}>
          {arrayDeviceCmpt}
        </CardTransbank>
      );
    });
    return (
      <div className="column-items" key={`transbank-first-column`} style={{ flex: '2' }}>
        {arrayCard}
      </div>
    );
  }

  function getColumn(orderColumns) {
    return orderColumns.map((order, index) => {
      const arrayDevicesName = order.split(',');
      const arrayDeviceCmpt = [];
      let isFlex1 = false;
      if (arrayDevicesName && arrayDevicesName.length) {
        arrayDevicesName.forEach((device) => {
          const arrayItemsCmpt = [];
          const infDev = infoDashboard[device];
          isFlex1 = infDev.name === 'SYS1' ? true : false;
          infDev.items.forEach((itm, index) => {
            arrayItemsCmpt.push(
              <InfoItem
                iconName={itm.iconName}
                status={itm.status}
                itemName={itm.name}
                itemLabel={itm.label}
                extraLabel={itm.inbound ? `In: ${itm.inbound} bps, Out: ${itm.outbound} bps` : ''}
                key={`item-${itm.name}-${itm.label}-${device}-${index}`}
              />
            );
          });
          console.log(infDev);

          arrayDeviceCmpt.push(
            <CardInfo
              status={infDev.status}
              style={{
                position: infDev.name === 'SYS1' ? 'absolute' : '',
                top: infDev.name === 'SYS1' ? '350px' : '',
                left: infDev.name === 'SYS1' ? '450px' : '',
              }}
              mainContent={
                <InfoHost
                  iconName={infDev.iconName}
                  type={infDev.custom_desc ? infDev.custom_desc : infDev.type}
                  hostname={infDev.name}
                  ip={`IP ${infDev.ip}`}
                />
              }
              items={arrayItemsCmpt}
            />
          );
        });
      } else {
        return null;
      }
      return (
        <>
          <div
            className="column-items"
            key={`column-${index}`}
            style={{ width: '287px', height: '100%', flex: isFlex1 ? 1 : 2 }}
          >
            {arrayDeviceCmpt}
          </div>
        </>
      );
    });
  }

  return (
    <section className="new-transback-view">
      <Toolbar title={`Nucleo`} />
      <div className="content">
        {getFirstColumn()}
        {getSecondColumn()}
        {getThirdColumn()}

        {/* FLECHAS DE PROXY > WEBLOGIC */}
        <div className="line" style={{ width: '135px', top: '440px', left: '437px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '435px', left: '562px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '445px', left: '562px' }}></div>
        {/* FLECHAS HA PROXY > AWS HIBA */}
        <div className="line" style={{ width: '70px', top: '680px', left: '437px' }}></div>
        <div className="line" style={{ width: '3px', height: '100px', top: '680px', left: '507px' }}></div>
        <div className="line" style={{ width: '65px', top: '780px', left: '507px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '775px', left: '562px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '785px', left: '562px' }}></div>
        {/* FLECHAS WEBLOGIC - AWS HIBA */}
        <div className="line" style={{ width: '3px', height: '55px', top: '655px', left: '800px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '702px', left: '789px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '702px', left: '800px' }}></div>
        {/* FLECHAS CLOVERLEAF - BD */}
        <div className="line" style={{ width: '340px', top: '85px', left: '1012px' }}></div>
        <div className="line" style={{ width: '3px', height: '65px', top: '85px', left: '1352px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '145px', left: '1343px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '145px', left: '1351px' }}></div>

        {/* FLECHAS WEBLOGIC - BD */}
        <div className="line" style={{ width: '140px', top: '292px', left: '1012px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '287px', left: '1140px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '297px', left: '1140px' }}></div>

        {/* FLECHAS BD - TANZU */}
        <div className="top-arrow" style={{ width: '14px', top: '395px', left: '1351px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '395px', left: '1343px' }}></div>
        <div className="line" style={{ width: '3px', height: '173px', top: '390px', left: '1352px' }}></div>
        <div className="top-arrow" style={{ width: '14px', top: '556px', left: '1343px' }}></div>
        <div className="bottom-arrow" style={{ width: '14px', top: '556px', left: '1351px' }}></div>
      </div>
      <div className="back-icon" onClick={selectMainDashboard}>
        <div>
          <img src={icons['back']} />
        </div>
      </div>
    </section>
  );
}

export default Nucleo;
