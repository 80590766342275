import React from 'react';
import { icons } from '../../utils/dictionaryIcons';
import { color_status } from '../../utils/dictionaryStates';
import './InfoEnlaceItem.css';

function InfoEnlaceItem({ iconName, status, itemName, itemLabel, extraLabel, idclass }) {
  return (
    <section className="infoenlaceitem-cmpt">
      {iconName ? (
        <div className="icon-infoenlaceitem" id={idclass}>
          <img src={icons[iconName]}/>
          <div className='status' style={{ backgroundColor: color_status[status] }}></div>
        </div>
      ) : null}
      {/* <div className="icon-infoenlaceitem">
        <img src={icons[iconName]} />
      </div> */}
      {/* <div className="content-infoenlaceitem">
        <div className="name-infoenlaceitem">{itemName}</div>
        <div className="label-infoenlaceitem">{itemLabel}</div>
        {extraLabel ? <div className="extralabel-infoenlaceitem">{extraLabel}</div> : null}
      </div> */}
      {/* <div
        className="status-infoenlaceitem"
        style={{ background: color_status[status], margin: extraLabel ? '8px' : '0px' }}
      /> */}
    </section>
  );
}

export default InfoEnlaceItem;
