import React from 'react';
import { color_status } from '../../utils/dictionaryStates';
import { icons } from '../../utils/dictionaryIcons';

import './CardVmware.css';

function CardVmware({ host, uptime, overall_status, cpu, memory, datastore }) {
  return (
    <section className="card-vmware-cmpt" style={{ border: `1px solid ${color_status[host.status]}` }}>
      <div className="item host" style={{ borderRight: `2px solid ${color_status[host.status]}` }}>
        {/* <div className="status" style={{ background: color_status[host.status] }} /> */}
        <div className="label-vmware">{host.name}</div>
      </div>
      {/* <div className="item" style={{width: '120px'}}> */}
      <div className="item" style={{ width: '70px' }}>
        <div className="label-vmware">Uptime</div>
        <div className="status" style={{ background: color_status[uptime.status] }} />
      </div>
      <div className="item" style={{ width: '100px' }}>
        <div className="label-vmware">Overall status</div>
        <div className="status" style={{ background: color_status[overall_status.status] }} />
      </div>
      <div className="item" style={{ width: '60px' }}>
        <div className="label-vmware">Cpu</div>
        <div className="status" style={{ background: color_status[cpu.status] }} />
      </div>
      <div className="item" style={{ width: '80px' }}>
        <div className="label-vmware">Memory</div>
        <div className="status" style={{ background: color_status[memory.status] }} />
      </div>
      {/* <div className="item">
        <div className="label-vmware"></div>
        <div className="status-vmware"></div>
      </div> */}
    </section>
  );
}

export default CardVmware;
