// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-vmware-cmpt {
  width: 100%;
  /* height: 16px; */
  display: flex;
  /* justify-content: space-between; */
  border-radius: 6px;
  border: 1px dashed #d0dced;
  /* margin-top: 10px; */
  /* padding: 8px 10px; */
  font-family: Khula;
  /* padding: 12px 0; */
}

.card-vmware-cmpt .item {
  display: flex;
  border-right: 1px dashed #d0dced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-vmware-cmpt .item.host {
  width: 120px;
  font-weight: bold;
}

.card-vmware-cmpt .item .status {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  margin-right: 6px;
}

.card-vmware-cmpt .item .label-vmware {
  /* margin-left: 6px; */
  font-size: 10px;
  margin: 4px 6px;
}
`, "",{"version":3,"sources":["webpack://./components/CardVmware/CardVmware.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,oCAAoC;EACpC,kBAAkB;EAClB,0BAA0B;EAC1B,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,eAAe;AACjB","sourcesContent":[".card-vmware-cmpt {\n  width: 100%;\n  /* height: 16px; */\n  display: flex;\n  /* justify-content: space-between; */\n  border-radius: 6px;\n  border: 1px dashed #d0dced;\n  /* margin-top: 10px; */\n  /* padding: 8px 10px; */\n  font-family: Khula;\n  /* padding: 12px 0; */\n}\n\n.card-vmware-cmpt .item {\n  display: flex;\n  border-right: 1px dashed #d0dced;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.card-vmware-cmpt .item.host {\n  width: 120px;\n  font-weight: bold;\n}\n\n.card-vmware-cmpt .item .status {\n  width: 10px;\n  height: 10px;\n  border-radius: 3px;\n  margin-right: 6px;\n}\n\n.card-vmware-cmpt .item .label-vmware {\n  /* margin-left: 6px; */\n  font-size: 10px;\n  margin: 4px 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
