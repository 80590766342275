// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

.tooltip {
  position: absolute;
  background: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  display: none;
  pointer-events: none;
  font-size: 12px;
  z-index: 1000;
  opacity: 1 !important;
}
`, "",{"version":3,"sources":["webpack://./components/Heatmap/style.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,oBAAoB;EACpB,eAAe;EACf,aAAa;EACb,qBAAqB;AACvB","sourcesContent":["/* styles.css */\n\n.tooltip {\n  position: absolute;\n  background: #333;\n  color: white;\n  padding: 5px;\n  border-radius: 3px;\n  display: none;\n  pointer-events: none;\n  font-size: 12px;\n  z-index: 1000;\n  opacity: 1 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
