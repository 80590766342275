import axios from 'axios';

const headers = {
  Authorization:
    'Api-Token dt0c01.CBI5AGUA3WLTLHGF2IIUYQIP.JZEXA6DJN4L3AUEYUBYAGMG3MBAFEGPF3MN32AYLUOANOGGPGI7QZU36KSSZLHAC',
};

const DYNA_CLA = 'https://tpw16984.live.dynatrace.com/api/v2';

const fetchEntitie = async (endpoint) => {
  try {
    const response = await axios.get(`${DYNA_CLA}/${endpoint}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Hubo un error al hacer la solicitud GET:', error.message);
    throw error;
  }
};

export default { fetchEntitie };
