// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alemana-status-cmpt {
  width: 224px;
  height: 88px;
  display: flex;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}

.alemana-status-cmpt .body-content-asc {
  width: calc(90% - 5px);
  padding: 8px;
}

.alemana-status-cmpt .status-asc {
  width: calc(10%-5px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.alemana-status-cmpt .status-asc .indicator-st-asc {
  width: 25px;
  height: 25px;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./components/AlemanaStatus/AlemanaStatus.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,aAAa;EACb,iDAAiD;EACjD,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".alemana-status-cmpt {\n  width: 224px;\n  height: 88px;\n  display: flex;\n  padding: 10px;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n  border-radius: 8px;\n}\n\n.alemana-status-cmpt .body-content-asc {\n  width: calc(90% - 5px);\n  padding: 8px;\n}\n\n.alemana-status-cmpt .status-asc {\n  width: calc(10%-5px);\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-end;\n}\n\n.alemana-status-cmpt .status-asc .indicator-st-asc {\n  width: 25px;\n  height: 25px;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
