// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infohost-cmpt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-family: Khula;
}

.infohost-cmpt .icon-infohost {
  width: 20%;
  height: 100%;
}
.infohost-cmpt .icon-infohost img {
  width: 28px;
  height: auto;
}

.infohost-cmpt .content-infohost {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.infohost-cmpt .content-infohost .type {
  width: 100%;
  font-size: 12px;
  line-height: 19.26px;
  color: #596c88;
  position: absolute;
  top: -2px;
}

.infohost-cmpt .content-infohost .hostname {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 25.67px;
  color: #596c88;
  position: absolute;
  top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infohost-cmpt .content-infohost .ip {
  width: 100%;
  font-size: 12px;
  line-height: 14.44px;
  color: #03c4c4;
  position: absolute;
  top: 26px;
  font-weight: bold;
}

/* .infohost-cmpt .status {
  width: 15%;
  height: 100%;
} */

/* .infohost-cmpt .status {
  width: 25px;
  height: 25px;
  border-radius: 3px;
} */
`, "",{"version":3,"sources":["webpack://./components/InfoHost/InfoHost.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,cAAc;EACd,kBAAkB;EAClB,SAAS;EACT,iBAAiB;AACnB;;AAEA;;;GAGG;;AAEH;;;;GAIG","sourcesContent":[".infohost-cmpt {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n  font-family: Khula;\n}\n\n.infohost-cmpt .icon-infohost {\n  width: 20%;\n  height: 100%;\n}\n.infohost-cmpt .icon-infohost img {\n  width: 28px;\n  height: auto;\n}\n\n.infohost-cmpt .content-infohost {\n  width: 80%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n\n.infohost-cmpt .content-infohost .type {\n  width: 100%;\n  font-size: 12px;\n  line-height: 19.26px;\n  color: #596c88;\n  position: absolute;\n  top: -2px;\n}\n\n.infohost-cmpt .content-infohost .hostname {\n  width: 100%;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 25.67px;\n  color: #596c88;\n  position: absolute;\n  top: 8px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.infohost-cmpt .content-infohost .ip {\n  width: 100%;\n  font-size: 12px;\n  line-height: 14.44px;\n  color: #03c4c4;\n  position: absolute;\n  top: 26px;\n  font-weight: bold;\n}\n\n/* .infohost-cmpt .status {\n  width: 15%;\n  height: 100%;\n} */\n\n/* .infohost-cmpt .status {\n  width: 25px;\n  height: 25px;\n  border-radius: 3px;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
