const zabbix_states = {
  0: 'OK',
  1: 'OK',
  2: 'WARNING',
  3: 'WARNING',
  4: 'CRITICAL',
  5: 'CRITICAL',
};

const color_status = {
  OK: '#0CD808',
  CRITICAL: '#FF5555',
  NOTDEFINED: '#AEB0B2',
  UNK: '#AEB0B2',
  WARNING: 'yellow'
};

export { zabbix_states, color_status };
