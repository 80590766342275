import React from 'react';
import './CardInfoTransbank.css';

import { color_status } from '../../utils/dictionaryStates';

function CardInfoTransbank({ status, mainContent, bodyContent = null, items, style }) {
  return (
    <section className="card-info-transbank-cmpt" style={style}>
      {/* <div className="main-info"> */}
        <div className="main-content">{mainContent}</div>
        <div className="status" style={{ background: color_status[status] }} />
        <div className="items-info">{items}</div>
      {/* </div> */}
    </section>
  );
}

export default CardInfoTransbank;
