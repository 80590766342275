// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  width: 100%;
  height: 76px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toolbar .title {
  font-family: Khula;
  font-weight: 800;
  font-size: 36px;
  line-height: 57.8px;
  color: #098983;
  margin-left: 20px;
}

.toolbar img {
  width: 305px;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./components/Toolbar/Toolbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".toolbar {\n  width: 100%;\n  height: 76px;\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.toolbar .title {\n  font-family: Khula;\n  font-weight: 800;\n  font-size: 36px;\n  line-height: 57.8px;\n  color: #098983;\n  margin-left: 20px;\n}\n\n.toolbar img {\n  width: 305px;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
