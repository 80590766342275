// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enlace-conexion-cmpt {
  width: 100%;
  height: 100%;
  position: absolute;
}

.enlace-conexion-cmpt .line-center {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./components/EnlaceConexion/EnlaceConexion.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".enlace-conexion-cmpt {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.enlace-conexion-cmpt .line-center {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
