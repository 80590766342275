const array_of_central_items = [
  {
    icon: 'caja',
    title: 'Cajas (xCash / Transbank)',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'caja',
    title: 'Transbank',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'query',
    title: 'Infraestructura Crítica',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  // {
  //   icon: 'query',
  //   title: 'Infraestructura Crítica 2',
  //   status: 'NOTDEFINED',
  //   problems: 0,
  //   total: 0,
  // },
  {
    icon: 'computer',
    title: 'Sistema',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'computer1',
    title: 'RIS - AGFA',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'computer1',
    title: 'RIS - LEBOX (TAKYON)',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'database',
    title: '',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'cubes',
    title: 'Modulab',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'clients',
    title: 'Clientes indicadores',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'shield',
    title: 'Nucleo Digital',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'shield',
    title: 'Nucleo',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'query',
    title: 'Tanzu',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'clients',
    title: 'Mov. Pacientes',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'dots',
    title: 'Fase 1',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'dots',
    title: 'Fase 2',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'computer',
    title: 'Sistema Admision',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'computer',
    title: 'Sistema Roble',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'computer',
    title: 'Sistema Aranceles',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'query',
    title: 'TYK',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'bond',
    title: 'Enlaces',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
  {
    icon: 'firewall',
    title: 'Firewall (Interno y Externo)',
    status: 'NOTDEFINED',
    problems: 0,
    total: 0,
  },
];

export default array_of_central_items;
