// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-info-transbank-cmpt {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 10px;
  border: 2px dashed gray;
  background-color: #ffffff;
  margin-top: 5px;
}


.card-info-transbank-cmpt .main-content {
  width: 177px;
}

.card-info-transbank-cmpt .status {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  margin-top: 5px;
}

.card-info-transbank-cmpt .items-info {
  width: 180px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./components/CardInfoTransbank/CardInfoTransbank.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,eAAe;AACjB;;;AAGA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":[".card-info-transbank-cmpt {\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n  border-radius: 10px;\n  border: 2px dashed gray;\n  background-color: #ffffff;\n  margin-top: 5px;\n}\n\n\n.card-info-transbank-cmpt .main-content {\n  width: 177px;\n}\n\n.card-info-transbank-cmpt .status {\n  width: 24px;\n  height: 24px;\n  border-radius: 3px;\n  margin-top: 5px;\n}\n\n.card-info-transbank-cmpt .items-info {\n  width: 180px;\n  display: flex;\n  flex-direction: column;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
