import Server from '../img/server_icon.svg';
import Servers from '../img/servers_icon.svg';
import Query from '../img/query_icon.svg';
import Db from '../img/db_icon.svg';
import Switch from '../img/switch_icon.svg';
import Port from '../img/port_icon.svg';
import Caja from '../img/caja_icon.svg';
import Computer from '../img/computer_icon.svg';
import Computer1 from '../img/computer1_icon.svg';
import Cubes from '../img/cubes_icon.svg';
import Clients from '../img/clients_icon.svg';
import Shield from '../img/shield_icon.svg';
import Dots from '../img/dots_icon.svg';
import Bond from '../img/bond_icon.svg';
import Firewall from '../img/firewall_icon.svg';
import World from '../img/world.png';
import Oracle from '../img/oracle.png';
import Cirion from '../img/cirion.png';
import Back from '../img/back.png';
import Cpu from '../img/cpu_icon.png';
import AlemanaGo from '../img/alemana_go.svg';
import AlemanaLogo from '../img/alemana_logo.svg';
import Azure from '../img/azure.png';
import Redhat from '../img/redhat.png';
import Ldap from '../img/ldap.svg';
import Computer2 from '../img/computer2.svg';
import Cloud from '../img/cloud.png';
import Java from '../img/java.png';
import Proxy from '../img/proxy.png';
import Service from '../img/service.png';
import RootDir from '../img/root-directory.png';

const icons = {
  server: Server,
  servers: Servers,
  query: Query,
  service: Service,
  cloud: Cloud,
  java: Java,
  proxy: Proxy,
  root_dir: RootDir,
  database: Db,
  switch: Switch,
  port: Port,
  caja: Caja,
  computer: Computer,
  computer1: Computer1,
  cubes: Cubes,
  clients: Clients,
  shield: Shield,
  dots: Dots,
  bond: Bond,
  firewall: Firewall,
  world: World,
  oracle: Oracle,
  cirion: Cirion,
  back: Back,
  cpu: Cpu,
  alemana_go: AlemanaGo,
  alemana_logo: AlemanaLogo,
  azure: Azure,
  redhat: Redhat,
  ldap: Ldap,
  computer2: Computer2,
};

const customDeviceIcon = {
  "Monitor ADCAS3": "computer",
  "ProxySwitcher": "switch",
  "Nucleo imágenes": "shield",
  "Nucleo Modulab": "shield",
  "Nucleo": "shield",
  "Weblogic JVM": "java",
  "Datasource-NucleoDG_cluster06": "database",
  "Datasource-NucleoDG": "database",
  "Servicios Caidos": "service",
};

export { icons, customDeviceIcon};
