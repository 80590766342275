// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardInfo-cmpt {
  width: 287px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 10px 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cardInfo-cmpt .main-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.cardInfo-cmpt .main-info .main-content {
  width: calc(100% - 35px);
  height: 100%;
}

.cardInfo-cmpt .main-info .status {
  width: 25px;
  height: 25px;
  border-radius: 3px;
}

.cardInfo-cmpt .body-info {
  display: flex;
  flex-wrap: wrap;
}

.cardInfo-cmpt .items-info {
  width: 100%;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./components/CardInfo/CardInfo.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,iDAAiD;AACnD;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,oBAAoB;AACtB;;AAEA;EACE,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".cardInfo-cmpt {\n  width: 287px;\n  display: flex;\n  flex-direction: column;\n  padding: 15px;\n  border-radius: 10px;\n  background-color: #ffffff;\n  margin: 10px 0;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n}\n\n.cardInfo-cmpt .main-info {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 10px;\n}\n\n.cardInfo-cmpt .main-info .main-content {\n  width: calc(100% - 35px);\n  height: 100%;\n}\n\n.cardInfo-cmpt .main-info .status {\n  width: 25px;\n  height: 25px;\n  border-radius: 3px;\n}\n\n.cardInfo-cmpt .body-info {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.cardInfo-cmpt .items-info {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
